<template>
  <Defaultlayout :userprofile="userprofile">
    <PageSlider></PageSlider>
    <div class="universityprogram">
      <div class="container">
        <h1 class="page-heading" style="padding-top: 60px;">Student Discount Program</h1>
        <!-- <img src="../../assets/images/universityprogram.jpeg" style="width: 100%; border-radius: 5px;"/> -->
          <h3>
            Ghar Bazaar’s University Student Program
          </h3>
          <h6>We know how difficult it can be for university students to source desi groceries with limited resources and
            transportation restrictions. And so, in our efforts to make shopping more convenient for them, Ghar Bazaar has
            established an exclusive university discount program. Our program is currently available for students from 3
            universities: the University of North Texas, the University of Arlington, & the University of Dallas.</h6>

          <h3>
            <i> Here is what you need to know about our university student discount program:</i>
          </h3>
          <ul>
            <li>We offer a 5% discount to qualifying university students.</li>
            <li>This discount is exclusively for current university students and cannot be used by other shoppers or
              ex-students.</li>
            <li>Each order must be worth $35 or more prior to taxes or any applicable fees.</li>
            <li>Each university student looking to avail of this discount must create a Ghar Bazaar account with his/her
              university-assigned student email.</li>
            <li>To qualify for free delivery, an order must be $50 or more.</li>
            <li>Some contingencies and restrictions may apply.</li>
          </ul>
          <h3> <i> Are you from one of the qualifying universities? </i><span>Then find your respective university promo code below:</span></h3>
          <div>
            <h2>University of Dallas</h2>
            <h5>Use promo code- GB_UTDSEWA</h5>
            <h5>Email domain to be used- @<a href="https://utdallas.edu" target="_blank">utdallas.edu</a></h5>
            <h5>Deliveries days- Wednesdays & Saturdays</h5>
            <h5>Eligible zip codes- 75080, 75252 & 75081</h5>
          </div>
          <div>
            <h2>University of North Texas</h2>
            <h5>Use promo code- GB_UNT5</h5>
            <h5>Email domain to be used- @<a href="https://my.unt.edu" target="_blank">my.unt.edu</a></h5>
            <h5>Deliveries days- Mondays & Sundays</h5>
            <h5>Eligible zip codes- 76201 to 76210</h5>
          </div>
          <div>
            <h2>University of Arlington</h2>
            <h5>Use promo code- GB_UTA</h5>
            <h5>Email domain to be used- @<a href="https://www.uta.edu/" target="_blank">mavs.uta.edu</a></h5>
            <h5>Deliveries days- Mondays & Thursdays</h5>
            <h5>Eligible zip codes- Arlington city addresses only.</h5>
          </div>
          <h4><i> For questions and concerns regarding this program email <a
              href="mailto:support@gharbazaar.com" target="_blank">support@gharbazaar.com</a></i></h4>
      </div>
    </div>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
  name: "StudentDiscountProgram",
  components: {
    Defaultlayout,
    PageSlider,
  },
  data() {
    return {
      loading: true,
      userprofile: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
};
</script>